<template>
	<layouts-identify :buttonBack="{ path: '/identify' }">
		<div class="pt-7 pb-4">
			<h3 class="ls-tight fw-bolder h3">Finalizar registro</h3>
			<p>Completá los siguientes datos para finalizar</p>
		</div>
		<form class="vstack gap-5" @submit.prevent="submit">
			<div>
				<div class="form-floating" :class="{'is-invalid': validation.fullname.$error}">
					<input type="text" id="fullname" v-model="fullname" class="form-control" :class="{'is-invalid': validation.fullname.$error}" placeholder="Ingresá tu nombre" v-focus>
					<label for="fullname">Nombre</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.fullname.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div v-if="target_type != 'email'">
				<div class="form-floating" :class="{'is-invalid': validation.email.$error}">
					<input type="email" id="email" v-model="email" class="form-control" :class="{'is-invalid': validation.email.$error}" placeholder="Ingresá tu email">
					<label for="email">Email</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.email.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<common-form-input-phone v-model="phone" title="Teléfono" placeholder="Ingresá tu número telefónico" :validation="validation.phone" v-if="target_type != 'phone'" />
			<div class="text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="text-center">
				Al registrarte aceptas nuestros <a href="/legal/terms" class="text-secondary fw-semibold" target="_blank">términos de servicio</a> y <a href="/legal/privacy" class="text-secondary fw-semibold" target="_blank">políticas de privacidad</a>.
			</div>
			<div>
				<button type="submit" class="btn btn-dark d-flex align-items-center w-100" :disabled="submitting">
					<div class="flex-fill me-n5">Finalizar registro</div>
					<span class="icon icon-sm ps-2">
						<i class="bi bi-person-plus-fill"></i>
					</span>
				</button>
			</div>
		</form>
	</layouts-identify>
</template>

<script>
	import { reactive, toRefs, onMounted } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { required, minLength, maxLength, email } from '@/helpers/i18n/validators';
	import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { query: { target_type, target }} = useRoute();
			const { redirectNext } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				fullname: '',
				email: '',
				phone: ''
			});

			const validatePhone = (value) => {
				return isValidPhoneNumber(value);
			}

			const validatePhoneMessage = ({ $model }) => {
				var message = null;

				switch(validatePhoneNumberLength($model)) {
					case 'NOT_A_NUMBER':
						message = 'Debe ser numérico';
						break;
					case 'INVALID_COUNTRY':
						message = 'Debe incluir el código de país, incluyendo el signo +';
						break;
					case 'TOO_SHORT':
						message = 'Es demasiado corto';
						break;
					case 'INVALID_LENGTH':
						message = 'Contiene una longitud inválida';
						break;
					case 'TOO_LONG':
						message = 'Es demasiado largo';
						break;
					default:
						message = 'Es inválido';
						break;
				}

				return message;
			}

			const validation = useVuelidate({
				fullname: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(191)
				},
				email: {
					required,
					email
				},
				phone: {
					validatePhone: helpers.withMessage(validatePhoneMessage, validatePhone)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				//Formatea el teléfono
				var sendForm = _.clone(form);
				sendForm.phone = parsePhoneNumber(sendForm.phone).number;

				submitting.value = true;
				store.dispatch('auth/signin', sendForm).then(onSuccess).catch(catchError);
			}

			const onSuccess = () => {
				store.dispatch('auth/me').then(redirectNext).catch(catchError);
			}

			onMounted(() => {
				switch(target_type) {
					case 'email':
						form.email = target;
						break;
					case 'phone':
						form.phone = target;
						break;
				}
			});

			return { ...toRefs(form), target_type, validation, validationExternal, submitting, submit };
		}
	}
</script>

<style scoped>
	.bi-phone {
		font-size: 4rem;
	}
</style>